import React, { useContext } from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faWindow,
  faUserFriends,
  faCloudUpload,
  faLockAlt,
  faShieldCheck,
  faCreditCard,
} from "@fortawesome/pro-duotone-svg-icons"
import {
  faCheck,
  faPlus,
  faArrowRight,
  faCompass,
  faEnvelope,
} from "@fortawesome/pro-solid-svg-icons"
import { faExternalLinkSquare } from "@fortawesome/pro-regular-svg-icons"
import { myContext } from "../components/context"

export default function Platform() {
  const value = useContext(myContext)
  return (
    <Layout>
      <Seo title="Self Service Visual Analytics" />
      <section className="bg-blue-100">
        <div className="lg:relative py-12 sm:pt-20 lg:py-20 container">
          <div className="grid lg:grid-cols-3">
            <div className="max-w-lg relative">
              <h1>The easiest platform</h1>
              <h1 className="font-medium">for data exploration</h1>
              <hr className="w-12 bg-transparent border-t-2 border-purple-600 mt-4 mb-6" />
              <img
                alt=""
                className="lg:absolute -left-24 lg:mt-8 md:mt-8 mt-4"
                src="https://storage.googleapis.com/keshif-website/Illustrations/Platform.png"
              />
            </div>
            <div className="grid lg:grid-cols-2 md:grid-cols-2 col-span-2 gap-x-5 gap-y-10">
              <div>
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center mb-4">
                  <FontAwesomeIcon
                    size="lg"
                    className="text-purple-600"
                    icon={faWindow}
                  />
                </div>
                <h5 className="font-medium mb-2">
                  Create, save, and share dashboards in seconds
                </h5>
                <p>
                  Our goal is to remove the complexities so you can focus on what matters: 
                  understanding your data through easy, rich exploration.
                </p>
              </div>
              <div>
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center mb-4">
                  <FontAwesomeIcon
                    size="lg"
                    className="text-purple-600"
                    icon={faUserFriends}
                  />
                </div>
                <h5 className="font-medium mb-2">
                  Collaborate on public and private datasets
                </h5>
                <p>
                  Control access to your shared dashboards at the data source.
                  Manage multi-seat subscriptions under a single account.
                </p>
              </div>
              <div>
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center mb-4">
                  <FontAwesomeIcon
                    size="lg"
                    className="text-purple-600"
                    icon={faCloudUpload}
                  />
                </div>
                <h5 className="font-medium mb-2">
                  Import data from cloud services easily
                </h5>
                <p>
                  Navigate and import data from Google Sheet, Google Drive,
                  Data.world, One-Drive, Dropbox & more.
                </p>
              </div>
              <div>
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center mb-4">
                  <FontAwesomeIcon
                    size="lg"
                    className="text-purple-600"
                    icon={faLockAlt}
                  />
                </div>
                <h5 className="font-medium mb-2">Data Privacy and Security</h5>
                <p>
                  We don't access or share data beyond enabling visual analytics, 
                  and we connect to data using secure channels.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative py-12 md:py-20">
        <div className="container relative flex flex-col">
          <img
            draggable="false"
            className="lg:block md:block hidden absolute right-0 -top-20 -z-1 pointer-events-none"
            alt="Section Pattern"
            src="https://storage.googleapis.com/keshif-website/Illustrations/Pattern.svg"
          />
          <div className="flex justify-between flex-wrap gap-5 lg:flex-row md:flex-row lg:items-center md:items-center flex-col">
            <h2>Plans</h2>
            <a
              href="https://help.keshif.me/article/44-plans-payments-faq"
              className="text-sm text-purple-600 font-medium"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon className="mr-2" icon={faArrowRight} />
              More information about plans and payments
            </a>
          </div>
          <div className="mt-10 flex gap-5 flex-wrap">
            <div className="bg-gray-50 py-10 px-7 rounded-sm max-w-md w-full">
              <h1 className="font-bold">Piri Reis</h1>
              <h3>Great for data enthusiasts & open data</h3>
              <ul className="mt-10 grid gap-5">
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1"
                    color="#10B981"
                    icon={faCheck}
                  />
                  <h4> Unlimited Datasets, Dashboards, Views</h4>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1"
                    color="#10B981"
                    icon={faCheck}
                  />
                  <h4>Filter, compare data to find meaningful trends</h4>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1"
                    color="#10B981"
                    icon={faCheck}
                  />
                  <h4> All-in-sync dashboards with all actions</h4>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1"
                    color="#10B981"
                    icon={faCheck}
                  />
                  <h4>Save personal dashboards, share public dashboards</h4>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1"
                    color="#10B981"
                    icon={faCheck}
                  />
                  <h4>
                    Import public datasets from Google Sheets, Google Drive, and
                    data.world
                  </h4>
                </li>
              </ul>
              <a
                className="w-full text-center btn-primary flex justify-center lg:mt-12 md:mt-8 mt-4"
                target="_blank"
                rel="noreferrer"
                href="https://explore.keshif.me"
              >
                <FontAwesomeIcon icon={faCompass} />
                &nbsp; Start Exploring Public Data!
              </a>
            </div>
            <div className="bg-gray-50 py-10 px-7 rounded-sm  max-w-md w-full">
              <h1 className="font-bold">Magellan</h1>
              <h3>Great for organizations & private data</h3>
              <ul className="mt-10 grid gap-5">
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1"
                    color="#10B981"
                    icon={faCheck}
                  />
                  <h4>All features in basic Piri Reis plan</h4>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1 text-purple-600"
                    icon={faPlus}
                  />
                  <h4>Share & view dashboards from private data</h4>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1 text-purple-600"
                    icon={faPlus}
                  />
                  <h4>Embed dashboards in external websites</h4>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1 text-purple-600"
                    icon={faPlus}
                  />
                  <h4>Import private data and generate maps</h4>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1 text-purple-600"
                    icon={faPlus}
                  />
                  <h4>Import data from other cloud services</h4>
                </li>
                <li className="flex">
                  <FontAwesomeIcon
                    className="mr-5 mt-1 text-purple-600"
                    icon={faPlus}
                  />
                  <h4>Premium support & onboarding</h4>
                </li>
              </ul>
              <button
                onClick={() =>
                  value.openModal(
                    "We’d like to receive information about self-service analytics for our organization."
                  )
                }
                className="w-full text-center btn-primary flex justify-center lg:mt-12 md:mt-8 mt-4"
              >
                <FontAwesomeIcon icon={faEnvelope} />
                &nbsp; Contact Us
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-100  py-12 md:py-20">
        <div className="container">
          <div className="grid lg:grid-cols-2 lg:gap-14 md:gap-10 gap-8">
            <div className="flex">
              <FontAwesomeIcon
                size="2x"
                className="text-purple-600 mr-4"
                icon={faShieldCheck}
              />
              <div>
                <h3 className="mb-2">Verified by Google</h3>
                <p>
                  Our Google integration is verified by Google. For GSuite
                  users, admins can whitelist Keshif to enable data exploration.
                </p>
              </div>
            </div>
            <div className="flex">
              <FontAwesomeIcon
                size="2x"
                className="text-purple-600 mr-4"
                icon={faWindow}
              />
              <div>
                <h3 className="mb-2">Secure Browser-only Data Access</h3>
                <p>
                  With our Google integration, your data stays at Google, and
                  the full content is only accessed locally by your web browser.
                </p>
              </div>
            </div>
            <div className="flex">
              <FontAwesomeIcon
                size="2x"
                className="text-purple-600 mr-4"
                icon={faLockAlt}
              />
              <div className="grid items-start">
                <h3 className="mb-2">Secure Platform Hosting</h3>
                <p>
                  Keshif is hosted on Google Cloud Platform, with built-in
                  end-to-end security and privacy features. We take proactive
                  measures to maintain a secure infrastructure.&nbsp;
                  <a
                    href="https://cloud.google.com/security"
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm text-purple-600 font-medium mt-7 max-w-fit"
                  >
                    Learn more about Google Cloud security&nbsp;
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faExternalLinkSquare}
                    />
                  </a>
                </p>
              </div>
            </div>
            <div className="flex">
              <FontAwesomeIcon
                size="2x"
                className="text-purple-600 mr-4"
                icon={faCreditCard}
              />
              <div className="grid items-start">
                <h3 className="mb-2">Secure Payments</h3>
                <p>
                  Payments to Keshif are processed using Stripe, a certified PCI
                  Service Provider Level 1. Keshif does not access or store full
                  credit card information. Stripe forces HTTPS for data transfer
                  and card numbers are encrypted with AES-256.&nbsp;
                  <a
                    href="https://stripe.com/docs/security/stripe"
                    target="_blank"
                    rel="noreferrer"
                    className="text-sm text-purple-600 font-medium mt-7 max-w-fit"
                  >
                    Learn more about Stripe’s stringent security&nbsp;
                    <FontAwesomeIcon
                      className="mr-2"
                      icon={faExternalLinkSquare}
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
